import React from 'react';
import { injectIntl } from 'react-intl';
import InputField from '@UI/InputField';
import Button from '@UI/Button';

function SearchForm({
  intl, state, setState, onSubmitCb,
}) {
  const onChange = (event) => {
    const { name } = event.target;
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSubmitCb();
  };

  return (
    <form onSubmit={onSubmit} name="articles-search-form" aria-label="articles-search-form">
      <InputField
        formGroupClass="divider"
        name="search"
        id="search"
        onChange={onChange}
        value={state.search}
        aria-label={intl.formatMessage({ id: 'SearchForm.SearchPlaceholder' })}
        placeholder={intl.formatMessage({ id: 'SearchForm.SearchPlaceholder' })}
        afterContent={(
          <Button size="m" fullWidth id="sidebar-submit" onClick={onSubmit}>
            {intl.formatMessage({ id: 'SearchForm.SearchSubmit' })}
          </Button>
        )}
      />
    </form>
  );
}

export default injectIntl(SearchForm);
