import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'rnd-helmet';
import Loadable from 'react-loadable';
import { CookiesProvider } from 'react-cookie';
import App from './client/App';

const supportedLanguages = [];
// Load the routes for the specific country.
const routes = require(`./config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
// Iterate through all supported languages for the country.
for (const lang of Object.keys(routes)) {
  supportedLanguages.push(lang);
}

let currentLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
// Get the current language based on the URL.
const { pathname } = window.location;
supportedLanguages.forEach((lang) => {
  if (pathname.startsWith(`/${lang}/`)) {
    currentLanguage = lang;
  }
});

let currentLangRel;
if (process.env.REACT_APP_COUNTRY_LOCALES) {
  currentLangRel = process.env.REACT_APP_COUNTRY_LOCALES;
} else {
  currentLangRel = currentLanguage;
}

const bootstrap = (
  <HelmetProvider>
    <BrowserRouter>
      <CookiesProvider>
        <App
          currentLanguage={currentLanguage}
          links={{
            items: window.myRandstadMenuItem ? [...(window.__LINKS__?.[currentLangRel]?.main || []), window.myRandstadMenuItem] : (window.__LINKS__?.[currentLangRel]?.main || []),
            utilityItems: window.__LINKS__?.[currentLangRel]?.utility,
            // TO DO: Fix
            // eslint-disable-next-line no-underscore-dangle
            languages: window.__LANGUAGE_ITEMS__,
          }}
          isEnableRelNavigation={window.isEnableRelNavigation}
        />
      </CookiesProvider>
    </BrowserRouter>
  </HelmetProvider>
);

const root = ReactDOM.hydrateRoot(
  document.getElementById('root'),
  bootstrap,
);

if (process.env.REACT_APP_ISDEV) {
  Loadable.preloadAll().then(() => {
    root.render(bootstrap);
    return true;
  }).catch((error) => console.error(error));
} else {
  root.render(bootstrap);
}
