import React from 'react';
import Navigation from '@UI/Navigation';

const languageFormatter = require('@Utils/languageFormatter');

class Header extends React.Component {
  constructor(props) {
    super(props);

    const routes = this.getRoutes(props.routes);
    const links = this.getLinks(props.links);

    // Fix current language
    const currentLanguage = languageFormatter(props.currentLanguage);

    this.currentRouteBasePath = Header.getBaseUrl(props.currentRoute, props.currentRoute?.path) || '';
    this.currentRouteUrl = props.currentRoute?.url || '';
    const homepageUrl = routes[currentLanguage]?.homepage?.url || '/';
    const currentLangUpper = currentLanguage.toUpperCase();
    const myRandstadBaseUrl = (homepageUrl + (process.env[`REACT_APP_MYRANDSTAD_${currentLangUpper}`] ?? process.env.REACT_APP_MYRANDSTAD_URL))
      .replace(/^\/\/?/, '/');

    // Get (ordered) languages from the s3 file and filter these with routes.
    const languageItems = Object.keys(links)
      .filter((language) => Object.keys(routes[language] || []).length > 0)
      .map((language) => ({
        language: language === 'el' ? 'gr' : language, // Change EL label to GR in language switcher.
        isActive: language === currentLanguage,
        url: language === currentLanguage
          ? null : Header.getBaseUrl(props.currentRoute, routes[language]?.[props.currentRoute.routeName]?.url || ''),
      }));

    const mainMenuItems = this.getMainMenu(links[currentLanguage]);

    const utilityMenuItems = links[currentLanguage]?.utility
      ? links[currentLanguage].utility
      : [];

    this.state = {
      languageItems,
      homepageUrl,
      myRandstadBaseUrl,
      mainMenuItems,
      utilityMenuItems,
      currentLanguage,
    };
  }

  getMainMenu(menu) {
    const mainMenu = menu?.main || [];
    const { url } = this.props;
    const currentMenuItemUrl = this.getCurrentMenuItemUrl(mainMenu);
    let menuHasActiveItem = false;

    return mainMenu.map((menuItem) => {
      const children = menuItem.children.map((child) => ({
        ...child,
        // Set active trail
        isActive: child.url === currentMenuItemUrl || child.url === url,
      }));

      const hasChildActive = children.find((child) => child.isActive)?.isActive || false;
      // Set active trail
      const isActive = menuHasActiveItem ? false : hasChildActive || menuItem.url === currentMenuItemUrl;

      // Should have one active item.
      if (hasChildActive) {
        menuHasActiveItem = true;
      }

      return {
        ...menuItem,
        isActive,
        children,
      };
    });
  }

  getCurrentMenuItemUrl(mainMenuItems) {
    // Get the menu item url that best matches the current url
    // (from a two level navigation - without homepage "/" that will always match)
    return mainMenuItems
      .map((item) => [item, item.children]).flat(2)
      .filter((item) => (this.currentRouteBasePath.startsWith(item.url) || this.currentRouteUrl.startsWith(item.url))
        && item.url !== '/')
      .sort((a, b) => b.url.length - a.url.length)[0]?.url || null;
  }

  // This method should not be static. It will be overridden in other opcos.
  // eslint-disable-next-line class-methods-use-this
  getRoutes(routes) {
    return routes;
  }

  // This method should not be static. It will be overridden in other opcos.
  // eslint-disable-next-line class-methods-use-this
  getLinks(links) {
    const menuLinks = {};

    // Fix menu links language code
    Object.keys(links || {}).forEach((lang) => {
      const languageCode = languageFormatter(lang);
      menuLinks[languageCode] = links[lang];
    });

    return menuLinks;
  }

  static getBaseUrl(route, url) {
    switch (route && route.routeName ? route.routeName : null) {
      case 'workforce360':
      case 'press':
      case 'career':
      case 'cleaner':
        return url.replace(':searchParams*', '');

      default:
    }

    return url;
  }

  static getAfterLinks() {
    const REGISTER_CV = process.env.REACT_APP_REGISTER_CV_ENABLED && process.env.REACT_APP_REGISTER_CV_ENABLED === 'true';
    if (REGISTER_CV) {
      return (
        <li className="navigation__service-item flex-shrink-0">
          <a href="/job-seeker/submit-your-cv/" aria-label="submit your CV" className="navigation__service-link hidden--from-l">
            <span className="icon icon--inline">
              <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#document" /></svg>
            </span>
          </a>
          <a href="/job-seeker/submit-your-cv/" className="navigation__service-link hidden--until-l">
            <span className="icon icon--inline">
              <svg><use xlinkHref="/themes/custom/bluex/dist/assets/image/icons.svg#document" /></svg>
            </span>
            <span>register CV</span>
          </a>
        </li>
      );
    }
    return null;
  }

  render() {
    const {
      myRandstadBaseUrl, homepageUrl, mainMenuItems, languageItems, utilityMenuItems, currentLanguage,
    } = this.state;
    const { classes } = this.props;
    const ALLOW_MY_RANDSTAD = process.env.REACT_APP_MYRANDSTAD_ENABLED && process.env.REACT_APP_MYRANDSTAD_ENABLED === 'true';
    const currentLangUpper = currentLanguage.toUpperCase();
    const LOGIN_URL = (process.env[`REACT_APP_MYRANDSTAD_LOGIN_${currentLangUpper}`] ?? (process.env.REACT_APP_MYRANDSTAD_LOGIN_URL ?? `${myRandstadBaseUrl}/login/`));

    const config = {
      // intl set global variable in server.js.
      // eslint-disable-next-line no-undef
      navigationHeadingText: intl.formatMessage({ id: 'MainNavigationMenu.Heading' }),
      homepageUrl,
    };

    const myRandstadConfig = {
      baseUrl: myRandstadBaseUrl,
      loginUrl: LOGIN_URL,
      label: 'my randstad',
    };

    const renderAfterLinks = Header.getAfterLinks();

    return (
      <Navigation classes={classes} mainMenu={mainMenuItems} languages={languageItems} utilityMenu={utilityMenuItems} showMyRandstad={ALLOW_MY_RANDSTAD} config={config} myRandstad={myRandstadConfig} afterLinks={renderAfterLinks} />
    );
  }
}

export default Header;
