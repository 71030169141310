import removeSpacesFromText from 'removeSpacesFromText';
import latinizeString from 'latinizeString';

/**
 * Encodes string to URL friendly path.
 *
 * It replaces the empty spaces with dashes,
 * encodes the URI component and replaces
 * language specific characters.
 *
 * @param string
 *   A normal string.
 *
 * @returns {string}
 *   Encoded string.
 */
function encodeString(string) {
  return encodeURIComponent(removeSpacesFromText(latinizeString(string)));
}

export default encodeString;
