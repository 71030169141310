import getCurrentPathChunks from './getCurrentPathChunks';

let appFnInitialized = false;

const updateSecondLevelMenuActiveLink = () => {
  if (typeof window === 'undefined' || !appFnInitialized) {
    appFnInitialized = true;
    return;
  }

  document
    .querySelectorAll('header.header .navigation__menu--sub .navigation__menu-item.navigation__menu-item--active')
    .forEach((element) => {
      element.classList.remove('navigation__menu-item--active');
    });

  const chunks = getCurrentPathChunks();
  const urls = [`${window.location.pathname}${window.location.search}`, ...chunks];

  urls.forEach((url) => {
    document
      .querySelectorAll(`header.header .navigation__menu--sub .navigation__menu-item a[href="${url}"]`)
      .forEach((element) => {
        element.parentElement.classList.add('navigation__menu-item--active');
      });
  });
};

export default updateSecondLevelMenuActiveLink;
