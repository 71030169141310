import React from 'react';
import { Route } from 'react-router-dom';
import NotFound from '@Pages/NotFound';

function handleUnknownParams() {
  return (
    <Route render={({ staticContext: routeStaticContext }) => {
      if (routeStaticContext) routeStaticContext.status = 404;
      return <NotFound staticContext={routeStaticContext} />;
    }}
    />
  );
}

export default handleUnknownParams;
