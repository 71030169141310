import queryStringHelper from 'query-string';
import removeSpacesFromText from 'removeSpacesFromText';
import latinizeString from 'latinizeString';
import params from '../../../config/params.config';

// TODO: Fix later.
// eslint-disable-next-line sonarjs/cognitive-complexity
function searchParamsStringify(pageType, searchParams) {
  const paramsMapInCorrectOrder = params[pageType] || {};
  let searchPath = '';
  let queryString = '';

  function getPrefixed(paramName) {
    const prefix = paramsMapInCorrectOrder[paramName];
    if (prefix) {
      return `${prefix}-${searchParams[paramName]}/`;
    }
    return `${searchParams[paramName]}/`;
  }

  /**
   * Helper function to build a query string for multiple facets.
   *
   * @param multipleFacets
   * @returns {string}
   */
  function stringifyMultipleFacets(facets) {
    const queryObject = {};
    for (const facetType of Object.keys(facets)) {
      if (Object.hasOwn(facets, facetType)) {
        const prefix = paramsMapInCorrectOrder[facetType];
        const paramsItems = [];
        for (const facetId of Object.keys(facets[facetType])) {
          if (Object.hasOwn(facets[facetType], facetId) && facets[facetType][facetId].checked) {
            paramsItems.push(facets[facetType][facetId].key);
          }
        }

        if (paramsItems) {
          queryObject[prefix] = paramsItems;
        }
      }
    }

    return queryStringHelper.stringify(queryObject, { arrayFormat: 'separator', arrayFormatSeparator: '_' });
  }

  function stringifySingleFacet(facets) {
    let path = '';
    for (const facetType of Object.keys(facets)) {
      if (Object.hasOwn(facets, facetType)) {
        for (const facet of Object.keys(facets[facetType])) {
          if (Object.hasOwn(facets[facetType], facet) && facets[facetType][facet].checked) {
            path = facets[facetType][facet].key;
          }
        }
      }
    }

    return path;
  }

  function isSingleFacet(facets) {
    let facetsCount = 0;
    for (const facetType of Object.keys(facets)) {
      if (Object.hasOwn(facets, facetType)) {
        for (const facetId of Object.keys(facets[facetType])) {
          if (Object.hasOwn(facets[facetType], facetId) && facets[facetType][facetId].checked) {
            facetsCount += 1;
          }
        }
      }
    }

    return facetsCount === 1;
  }

  const hasPageParam = !!searchParams.page;

  for (const paramName of Object.keys(paramsMapInCorrectOrder)) {
    if (searchParams[paramName]) {
      if (paramName === 'query') {
        const value = paramName === 'query' ? latinizeString(removeSpacesFromText(searchParams[paramName])).toLowerCase() : searchParams[paramName];
        const stringifiedQuery = queryStringHelper.stringify({ [paramsMapInCorrectOrder[paramName]]: value });
        queryString += queryString !== '' && value ? `&${stringifiedQuery}` : stringifiedQuery;
      } else if (paramName === 'facets') {
        if (searchParams[paramName]) {
          if (isSingleFacet(searchParams[paramName])) {
            searchPath += stringifySingleFacet(searchParams[paramName]);
          } else {
            const multiFacetQueryString = stringifyMultipleFacets(searchParams[paramName]);
            queryString += queryString !== '' && multiFacetQueryString ? `&${multiFacetQueryString}` : multiFacetQueryString;
          }
        }
      } else {
        searchPath += getPrefixed(paramName);
      }
    }
  }

  if (searchPath !== '') {
    searchPath = `${searchPath}/`;
  } else {
    // searchPath = '/';
  }

  if (hasPageParam) {
    searchPath += `page-${searchParams.page}/`;
  }

  if (queryString !== '') {
    searchPath += `?${queryString}`;
  }

  return searchPath;
}
export default searchParamsStringify;
