import React, { useEffect, useState } from 'react';
import DOMPurify from 'isomorphic-dompurify';
import getClosedMarketingMessagesFromCookie from '@ffw/randstad-shared-components-tf/src/utils/marketingMessages/getClosedMarketingMessagesFromCookie';
import setClosedMarketingMessagesCookie from '@ffw/randstad-shared-components-tf/src/utils/marketingMessages/setClosedMarketingMessagesCookie';
import Banner from '@ffw/randstad-shared-components-tf/src/components/notifications/Banner';
import getUserData from '@ffw/randstad-shared-components-tf/src/utils/getUserData';

function MarketingMessages({
  marketingMessages,
  locale,
}) {
  const [closedMarketingMessages, setClosedMarketingMessages] = useState(getClosedMarketingMessagesFromCookie());
  const now = new Date();
  const { loginStatus: isAuth } = getUserData();

  const [hasRendered, setHasRendered] = useState(false);

  function showNextMarketingMessage() {
    const nextMarketingMessage = document.querySelector('.marketing-message.hidden');
    if (nextMarketingMessage) {
      nextMarketingMessage.classList.remove('hidden');
    }
  }

  const showMarketingMessage = (marketingMessage) => {
    const {
      id, startDate, endDate, isPublic,
    } = marketingMessage;

    return (
      !closedMarketingMessages.includes(id)
      && (isAuth || isPublic)
      && now >= new Date(startDate)
      && now <= new Date(endDate)
      && locale === marketingMessage.language
    );
  };

  const closeMessage = (id) => {
    const updatedIds = [...closedMarketingMessages, id];
    setClosedMarketingMessagesCookie(updatedIds);
    setClosedMarketingMessages(getClosedMarketingMessagesFromCookie());
    showNextMarketingMessage();
  };

  useEffect(() => {
    setHasRendered(true);
  }, []);

  useEffect(() => {
    showNextMarketingMessage();
  }, [hasRendered]);

  if (hasRendered) {
    return (
      <div>
        <div className="full-width-bottom">
          {marketingMessages.map((mM) => {
          // Ignore ESLint: Dangerous property 'dangerouslySetInnerHTML'
          // eslint-disable-next-line react/no-danger
            const message = <span className="body-copy" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(mM.message) }} />;
            const redirectLinkButton = mM.redirectLink
              ? (
                <a
                  href={mM.redirectLink}
                  target={!mM.redirectLink.startsWith('/') ? '_blank' : ''}
                  rel="noreferrer"
                  onClick={() => {
                    if (mM.redirectLink.startsWith('/')) {
                      closeMessage(mM.id);
                    }
                  }}
                >
                  {mM.redirectLinkText || mM.redirectLink}
                </a>
              )
              : undefined;

            return showMarketingMessage(mM) ? (
              <Banner
                key={mM.id}
                id={mM.id.toString()}
                title={mM.title}
                button={redirectLinkButton}
                onClose={() => closeMessage(mM.id)}
              >
                {message}
              </Banner>
            ) : null;
          })}
        </div>
      </div>
    );
  }

  return null;
}

export default MarketingMessages;
