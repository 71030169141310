import { useIntl } from 'react-intl';

function DateFormatter(dateString, format = 'd M Y') {
  // Handle, date strings as well as unix timestamps values.
  const date = !Number.isNaN(new Date(dateString).getTime()) ? new Date(dateString) : new Date(dateString * 1000);

  const intl = useIntl();
  const nth = function getOrdinalSuffix(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const month = intl.formatMessage({ id: `Date.Month${date.getMonth()}` });
  const dayFormatted = date.getDate();

  switch (format) {
    case 'd M Y':
      return `${date.getDate()} ${month} ${date.getFullYear()}`;
    case 'northAmericanDate':
      return `${month} ${dayFormatted}${nth(dayFormatted)}, ${date.getFullYear()}`;
    default:
      return `${date.getDate()} ${month} ${date.getFullYear()}`;
  }
}

export default DateFormatter;
