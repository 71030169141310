function getCurrentPathChunks() {
  const results = [];

  let currentPath = window.location.pathname;
  if (!currentPath.startsWith('/')) {
    currentPath = `/${currentPath}`;
  }
  if (!currentPath.endsWith('/')) {
    currentPath = `${currentPath}/`;
  }

  const chunks = currentPath.split('/');
  chunks.shift();
  chunks.pop();
  while (chunks.length) {
    results.push(`/${chunks.join('/')}/`);
    chunks.pop();
  }

  return results;
}

export default getCurrentPathChunks;
