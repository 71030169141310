function getCareerCategorySpecificMeta(categoryName, intl, metaTitle, metaDescription, titleTop) {
  switch (categoryName) {
    case 'curriculum vitae':
      metaDescription = intl.messages['Career.CategoryMetaDescriptionTitle.CurriculumVitae']
        ? intl.formatMessage({ id: 'Career.CategoryMetaDescriptionTitle.CurriculumVitae' }, { sitename: process.env.REACT_APP_SITENAME })
        : metaDescription;
      break;
    case 'personal branding':
      metaDescription = intl.messages['Career.CategoryMetaDescriptionTitle.PersonalBranding']
        ? intl.formatMessage({ id: 'Career.CategoryMetaDescriptionTitle.PersonalBranding' }, { sitename: process.env.REACT_APP_SITENAME })
        : metaDescription;
      metaTitle = intl.messages['Career.CategoryTitle.PersonalBranding']
        ? intl.formatMessage({ id: 'Career.CategoryTitle.PersonalBranding' }, { sitename: process.env.REACT_APP_SITENAME })
        : metaTitle;
      break;
    case 'rentas':
      metaDescription = intl.messages['Career.CategoryMetaDescriptionTitle.Rentas']
        ? intl.formatMessage({ id: 'Career.CategoryMetaDescriptionTitle.Rentas' }, { sitename: process.env.REACT_APP_SITENAME })
        : metaDescription;
      break;
    default:
      metaDescription = intl.messages['Career.CategoryMetaDescriptionTitle']
        ? intl.formatMessage({ id: 'Career.CategoryMetaDescriptionTitle' }, {
          sitename: process.env.REACT_APP_SITENAME,
          category: categoryName,
        })
        : metaDescription;
      metaTitle = intl.messages['Career.CategoryTitle']
        ? intl.formatMessage({ id: 'Career.CategoryTitle' }, {
          sitename: process.env.REACT_APP_SITENAME,
          category: categoryName,
        })
        : metaTitle;
  }

  return {
    metaTitle,
    metaDescription,
    titleTop,
  };
}

export default getCareerCategorySpecificMeta;
