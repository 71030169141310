import {
  useContext, useState, useEffect, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { TrackingContext } from 'trackingContext';

/**
 * Check whether we should attach optimizely on the current page url.
 *
 * @param {string} currentUrl
 *   Url of current page.
 * @param {object} config
 *   Optimizely settings, stored in DynamoDB, set from CMS.
 *
 * @returns {boolean}
 *   True if allowed, false otherwise.
 */
export const shouldAttachOptimizely = (currentUrl, config = {}) => {
  if (!config.enabled || !currentUrl) {
    return false;
  }
  const newLineRegex = /\r\n|\r|\n/;
  const enabledPathsString = config?.enabledPaths?.trim() || '';
  const enabledRegexesString = config?.enabled_regex?.trim() || '';
  // No specific path settings means enabled for all routes.
  if (!enabledPathsString && !enabledRegexesString) {
    return true;
  }
  // Checks based on full paths.
  if (enabledPathsString) {
    const enabledPaths = enabledPathsString.split(newLineRegex);
    if (enabledPaths.includes(currentUrl)) {
      return true;
    }
  }
  // Checks based on path regexes.
  if (enabledRegexesString) {
    const enabledRegexes = enabledRegexesString.split(newLineRegex);
    const match = enabledRegexes.some((pattern) => {
      const regex = new RegExp(pattern);
      return pattern && regex.test(currentUrl);
    });
    // This might seem strange, but it's done to avoid unexpected behaviour in
    // possible future changes.
    if (match) {
      return true;
    }
  }

  return false;
};

/**
 * Returns link/script jsx elements, if allowed on current page.
 *
 * @param {string} currentUrl
 *   Url of current page.
 * @param {object} config
 *   Optimizely settings, stored in DynamoDB, set from CMS.
 *
 * @returns {string}
 */
export const getOptimizelyTags = (currentUrl, config = {}) => {
  if (shouldAttachOptimizely(currentUrl, config)) {
    const id = config?.id;
    if (id) {
      return `<script type="text/plain" class="optanon-category-C0003" src="https://cdn.optimizely.com/js/${id}.js"></script>`;
    }
  }
  return '';
};

/**
 * Returns a <Helmet> with optimizely script/link tags based on config.
 *
 * Used, instead of old server implementation, as we need to handle cases when a
 * React router change occurs, without page refresh.
 */
function Optimizely() {
  const { optimizelySettings = {} } = useContext(TrackingContext);
  const initialRender = useRef(true);
  const location = useLocation();

  const [settings] = useState(optimizelySettings);

  // Handle optimizely script attach,
  // on react page change.
  useEffect(() => {
    const id = settings?.id;
    const src = `https://cdn.optimizely.com/js/${id}.js`;
    if (id && !initialRender.current && window.OneTrust) {
      const type = shouldAttachOptimizely(location.pathname, settings)
        ? 'activate'
        : 'disable';
      // https://docs.developers.optimizely.com/experimentation/v10.0.0-web/reference/activate
      window.optimizely = window.optimizely || [];
      window.optimizely.push({ type });
      // https://community.cookiepro.com/s/article/UUID-730ad441-6c4d-7877-7f85-36f1e801e8ca?language=en_US
      window.OneTrust.InsertScript(src, 'head', null, null, 'C0003');
    }

    if (initialRender.current) {
      initialRender.current = false;
    }

    return () => {
      if (id) {
        delete window.optimizely;
        const elements = document.querySelectorAll(`script[src="${src}"]`);
        if (elements && elements.length) {
          elements.forEach((element) => {
            element.remove();
          });
        }
      }
    };
  }, [location.pathname, location.search, settings]);

  return null;
}

export default Optimizely;
