import latinizeString from 'latinizeString';
import removeSpacesFromText from 'removeSpacesFromText';
import searchParamsStringify from '../../server/utils/searchParamsStringify';

/**
 * Parses elastic search aggregations.
 *
 * @param {object} facets
 *   Elasticsearch aggregations.
 *
 * @returns {{}}
 *   Returns articles facets.
 */
const parseFacets = (facets) => {
  const facetParams = {};
  for (const facetType of Object.keys(facets)) {
    facetParams[facetType] = {};
    if (facets[facetType].buckets !== undefined) {
      facets[facetType].buckets.forEach((item) => {
        if (item.doc_count > 0) {
          facetParams[facetType][item.key] = {
            key: encodeURIComponent(removeSpacesFromText(latinizeString(item.key.toLowerCase()))),
            name: item.key,
            checked: false,
          };
        }
      });
    }
  }

  return facetParams;
};

/**
 * Builds href for a given facet.
 *
 * @param {string }facetId
 *   Facet id, coming from elastic search.
 * @param {object} searchParams
 *   Search params object.
 * @param {string} pageType
 *   The search page type.
 *
 * @returns {string}
 *   Returns facet's href.
 */
const facetHref = (facetId, searchParams, pageType) => {
  const params = { ...searchParams, ...{ page: null } };
  if (Object.prototype.hasOwnProperty.call(params, 'facets') && Object.keys(params.facets).length) {
    for (const facetType of Object.keys(params.facets)) {
      const facetItems = params.facets[facetType];
      if (Object.prototype.hasOwnProperty.call(facetItems, facetId)) {
        const toCheck = !params.facets[facetType][facetId].checked;
        params.facets[facetType][facetId].checked = toCheck;
        const href = searchParamsStringify(pageType, params);
        params.facets[facetType][facetId].checked = !toCheck;

        return href;
      }
    }
  }
  /**
   * We need to return default value here because of:
   * Expected to return a value at the end of arrow function.eslintconsistent-return!
  */
  return '';
};

const getCurrentCategoryName = (facets) => {
  let currentCategory = '';
  let categoriesCount = 0;

  for (const facetType of Object.keys(facets)) {
    for (const category of Object.keys(facets[facetType])) {
      const categoryItem = facets[facetType][category];

      if (categoryItem.checked) {
        currentCategory = categoryItem.name || '';
        categoriesCount += 1;
      }
    }
  }

  return categoriesCount === 1 ? currentCategory : '';
};

export { parseFacets, facetHref, getCurrentCategoryName };
