import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import searchParamsStringify from '@Utils/searchParamsStringify';

function handleFirstPageRedirect(baseUrl, searchParams, pageType) {
  return (
    <Route render={({ staticContext: routeStaticContext }) => {
      if (routeStaticContext) routeStaticContext.status = 301;
      return <Redirect to={`${baseUrl}${searchParamsStringify(pageType, { ...searchParams, ...{ page: null } })}`} />;
    }}
    />
  );
}

export default handleFirstPageRedirect;
