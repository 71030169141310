import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import Pagination from '@UI/Pagination';
import Logger from 'logger';

function Pager({
  intl, previous, pagesList, next,
}) {
  let pages = [];

  let currentPage = null;
  let previousArrowLink = null;
  let nextArrowLink = null;

  const scrollTo = () => {
    setTimeout(() => {
      // IE fallback.
      try {
        document.querySelector('.sortbar').scrollIntoView({ behavior: 'smooth' });
      } catch (error) {
        Logger.error('The browser doesn\'t support Smooth scroll', 'components/Pager');
      }
    }, 500);
  };

  // TODO: Not used in orbit. Remove after while.
  // if (props.first && Object.keys(props.first).length) {
  //   first =
  //     <li className="pagination__item" data-rs-pagination-control="data-rs-pagination-control">
  //       <Link className="pagination__control" onClick={scrollTo} to={props.first.url} onMouseDown={props.first.onMouseDown} title={intl.formatMessage({ id: "Pager.GoToFirstTitle" })}>
  //         <span aria-hidden={true}>{intl.formatMessage({ id: "Pager.GoToFirstLabel" })}</span>
  //         <span className="hidden--visually">{intl.formatMessage({ id: "Pager.GoToFirstHidden" })}</span>
  //       </Link>
  //     </li>;
  // }

  if (previous && Object.keys(previous).length) {
    previousArrowLink = {
      text: intl.formatMessage({ id: 'Pager.GoToPreviousHidden' }),
      url: previous.url,
      attributes: {
        onClick: scrollTo,
        to: previous.url,
        onMouseDown: previous.onMouseDown,
        title: intl.formatMessage({ id: 'Pager.GoToPreviousTitle' }),
      },
    };
  }

  if (pagesList && pagesList.length) {
    pages = pagesList.map((page) => ({
      text: page.text,
      url: page.url,
      attributes: {
        onMouseDown: page.onMouseDown,
        title: intl.formatMessage({ id: 'Pager.GoToPage' }, { text: page.text }),
        onClick: scrollTo,
        to: page.url,
      },
    }));

    const currentPageObj = pagesList.filter((page) => (page.isCurrent ? page.text : null));
    currentPage = currentPageObj[0] && currentPageObj[0].text;
  }

  if (pagesList && pagesList.length && next && Object.keys(next).length) {
    nextArrowLink = {
      text: intl.formatMessage({ id: 'Pager.GoToNextHidden' }),
      url: next.url,
      attributes: {
        onClick: scrollTo,
        to: next.url,
        onMouseDown: next.onMouseDown,
        title: intl.formatMessage({ id: 'Pager.GoToNextTitle' }),
      },
    };
  }
  // TODO: Not used in orbit. Remove after while.
  // if (props.last && Object.keys(props.last).length) {
  //   last =
  //     <li className="pagination__item" data-rs-pagination-control="data-rs-pagination-control">
  //       <Link className="pagination__control" onClick={scrollTo} to={props.last.url} onMouseDown={props.last.onMouseDown} title={intl.formatMessage({ id: "Pager.GoToLastTitle" })}>
  //         <span aria-hidden={true}>{intl.formatMessage({ id: "Pager.GoToLastLabel" })}</span>
  //         <span className="hidden--visually">{intl.formatMessage({ id: "Pager.GoToLastHidden" })}</span>
  //       </Link>
  //     </li>;
  // }

  return (
    <Pagination pages={pages} currentPage={currentPage} as={Link} previousArrowLink={previousArrowLink} nextArrowLink={nextArrowLink} />
  );
}

export default injectIntl(Pager);
