import React from 'react';
import { injectIntl } from 'react-intl';

function NoResults({ intl }) {
  return (
    <li className="blog-overview__item">{intl.formatMessage({ id: 'NoResults.message' })}</li>
  );
}

export default injectIntl(NoResults);
