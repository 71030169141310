import { getCurrentCategoryName } from 'facetsHelper';

function updatePressSpecificMeta(intl, searchParams) {
  let metaTitle = intl.formatMessage({ id: 'Press.Title' }, { sitename: process.env.REACT_APP_SITENAME });
  let metaDescription = intl.messages['Press.MetaDescription'] ? intl.messages['Press.MetaDescription'] : null;
  let titleTop = intl.formatMessage({ id: 'PressHeader.TitleTop' });
  let titleBottom = intl.messages['PressHeader.TitleBottom'] ? intl.formatMessage({ id: 'PressHeader.TitleBottom' }) : null;

  const categoryName = searchParams.facets ? getCurrentCategoryName(searchParams.facets) : '';
  if (categoryName.length) {
    metaTitle = intl.messages['Press.CategoryTitle'] ? intl.formatMessage({ id: 'Press.CategoryTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaTitle;
    metaDescription = intl.messages['Press.CategoryMetaDescriptionTitle'] ? intl.formatMessage({ id: 'Press.CategoryMetaDescriptionTitle' }, {
      sitename: process.env.REACT_APP_SITENAME,
      category: categoryName,
    }) : metaDescription;
    titleTop = intl.messages['PressHeader.CategoryTitleTop'] ? intl.formatMessage({ id: 'PressHeader.CategoryTitleTop' }, { category: categoryName }) : titleTop;
    titleBottom = intl.messages['PressHeader.CategoryTitleTopBottom'] ? intl.formatMessage({ id: 'PressHeader.CategoryTitleTopBottom' }) : titleBottom;
  }

  return {
    metaTitle,
    metaDescription,
    titleTop,
    titleBottom,
  };
}

export default updatePressSpecificMeta;
