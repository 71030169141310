import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { Link, useRouteMatch, useHistory } from 'react-router-dom';
import SearchForm from '@Filters/SearchForm';
import Accordion from '@UI/Accordion';
import AccordionItem from '@UI/AccordionItem';
import Checkbox from '@UI/Checkbox';
import Stackable from '@UI/Stackable';
import Filter from '@UI/Filter';
import Button from '@UI/Button';
import searchParamsStringify from '@Utils/searchParamsStringify';
import { facetHref } from 'facetsHelper';

function BlogFilter({
  intl,
  pageType,
  searchParams,
  resultsTotal,
}) {
  const route = useRouteMatch();
  const baseUrl = route.path.replace(':searchParams*', '');
  const history = useHistory();
  const filters = searchParams.facets ? { ...searchParams.facets } : {};

  const [state, setState] = useState({
    search: searchParams.query || '',
  });

  const updateFacet = (facetType, name, checked) => {
    const params = { ...searchParams, ...{ page: null } };
    params.facets[facetType][name].checked = checked;

    history.push(`${baseUrl}${searchParamsStringify(pageType, params)}`);
  };

  const onChange = (event) => {
    const { name } = event.target;
    const { facetType } = event.target.dataset;
    const { checked } = event.target;

    updateFacet(facetType, name, checked);
  };

  const updateSearchParams = (query) => {
    const params = searchParamsStringify(pageType, {
      ...searchParams,
      ...{ query: query || null },
    });
    history.push(`${baseUrl}${params}`);
  };

  const onSubmit = () => {
    searchParams.page = null;
    updateSearchParams(state.search);
  };

  const renderAccordionItems = () => {
    const accordionItems = [];
    for (const [facetType, facetItems] of Object.entries(filters)) {
      if (Object.keys(facetItems).length) {
        const facetFilters = [];
        const checkboxInputProps = [];

        let checkboxKey = '';
        for (const [facetId, facetData] of Object.entries(facetItems)) {
          // Undefined as a string is the case where an invalid category parameter is typed manually in the URL.
          // Invalid means that it does not exists as a facet in elastic.
          if (facetId !== 'undefined') {
            checkboxKey = facetId;
            checkboxInputProps.push({
              key: facetData.key,
              name: facetData.name,
              checked: facetData.checked,
              href: `${baseUrl}${facetHref(facetId, searchParams, pageType)}`,
              label: facetData.name,
              onChange,
              data: {
                'data-facet-type': facetType,
              },
            });
          }
        }

        facetFilters.push(
          <Stackable key={checkboxKey} label={facetType} hideLabel>
            {checkboxInputProps.map(({ id, data, ...props }) => (
              <Checkbox key={id} {...props} {...data} />
            ))}
          </Stackable>,
        );

        accordionItems.push(
          <AccordionItem
            key={facetType}
            id={`sidebar-filter-${facetType}`}
            title={
              intl.messages[`BlogFilter.${facetType}`]
                ? intl.formatMessage({ id: [`BlogFilter.${facetType}`] })
                : facetType
            }
            expanded
            HeadingTag="h2"
            ariaLabel={intl.formatMessage({ id: 'AccordionItem.AriaLabel' })}
          >
            {facetFilters}
          </AccordionItem>,
        );
      }
    }

    return accordionItems;
  };

  const accordionItems = renderAccordionItems();

  const renderFilter = () => {
    let filterCount = searchParams.query ? 1 : 0;
    if (searchParams.facets) {
      for (const [, facetItems] of Object.entries(searchParams.facets)) {
        for (const [, value] of Object.entries(facetItems)) {
          if (value.checked) {
            filterCount += 1;
          }
        }
      }
    }
    return filterCount;
  };

  const filterCount = renderFilter();

  return (
    <Filter
      title={intl.formatMessage({ id: 'SearchFilters.FilterTitle' })}
      mobileTitle={intl.formatMessage({ id: 'SearchFilters.FilterTitle' })}
      footer={(
        <Button fullWidth type="filled" onClick={onSubmit}>
          {intl.formatMessage(
            { id: 'Blog.ResultCount' },
            { count: resultsTotal },
          )}
        </Button>
      )}
      clearLink={
        filterCount ? (
          <Link
            className="link"
            to={route.path.replace(':searchParams*', '')}
            onClick={() => {
              setState({ search: '' });
            }}
          >
            {intl.formatMessage(
              { id: 'SearchForm.ClearSearch' },
              { filterCount },
            )}
          </Link>
        ) : null
      }
    >
      <SearchForm
        pageType={pageType}
        searchParams={searchParams}
        state={state}
        setState={setState}
        onSubmitCb={onSubmit}
      />
      <Accordion>{accordionItems}</Accordion>
    </Filter>
  );
}
export default injectIntl(BlogFilter);
