const Logger = () => {
// Should work only on server.
  if (typeof window === 'undefined') {
    return require('@ffw/logger-lib');
  }
  // Client-side
  return {
    log: (...args) => {
      // Implement mock logging or leave it empty
      // eslint-disable-next-line no-console
      console.log(...args); // Example implementation
    },
    error: (...args) => {
      console.error(...args);
    },
    info: (...args) => {
      // Implement mock logging or leave it empty
      // eslint-disable-next-line no-console
      console.info(...args);
    },
  };
};

export default Logger();
