import generateDataLayer from 'dataLayerHelper';

function handleStaticContext(staticContext, locale) {
  if (staticContext && staticContext.data) {
    const { appConfigurations } = staticContext;
    const { 'rbd_tracking.settings': rbdTrackingSettings } = appConfigurations;
    const { gtm } = rbdTrackingSettings || {};
    const { enabled: gtmEnabled } = gtm || {};

    if (gtmEnabled) {
      staticContext.dataLayer = generateDataLayer('other_content', locale);
    }
  }
}

export default handleStaticContext;
