import React from 'react';
import searchParamsStringify from '@Utils/searchParamsStringify';
import UIBreadcrumbs from '@UI/Breadcrumbs';

function Breadcrumbs({
  breadcrumbs, currentRoute, searchParams, pageType,
}) {
  const baseUrl = currentRoute && currentRoute.path ? currentRoute.path.replace(':searchParams*', '') : null;
  let breadcrumbList = [];
  if (breadcrumbs && breadcrumbs.items !== undefined) {
    breadcrumbs.items.forEach((breadcrumb) => {
      if (breadcrumb.link !== null) {
        breadcrumbList.push({
          title: breadcrumb.title,
          url: breadcrumb.link,
        });
      } else {
        breadcrumbList.push({
          title: breadcrumb.title,
        });
      }
    });
  } else {
    return null;
  }
  const breadcrumbItems = {
    categories: {
      nullValues: [
        'page',
        'query',
      ],
    },
    tags: {
      nullValues: [
        'page',
        'query',
      ],
    },
  };
  if (searchParams && searchParams.facets) {
    Object.keys(searchParams.facets).forEach((facetType) => {
      const facets = searchParams.facets[facetType];
      let count = 0;
      let facetBreadcrumbs = [];
      Object.keys(facets).forEach((facet) => {
        if (facets[facet].checked && count < 1) {
          const facetBuilder = { facets: {} };
          facetBuilder.facets[facetType] = { ...facets };
          breadcrumbItems[facetType].nullValues.forEach((facetName) => {
            facetBuilder[facetName] = null;
          });
          const url = `${baseUrl}${searchParamsStringify(pageType, facetBuilder)}`;

          facetBreadcrumbs.push({
            title: facets[facet].name,
            url,
          });
          count = +1;
        } else if (facets[facet].checked && count >= 1) {
          facetBreadcrumbs = [];
        }
      });
      breadcrumbList = [...breadcrumbList, ...facetBreadcrumbs];
    });
  }

  // Highlight the last item.
  if (breadcrumbList.length > 1 && breadcrumbList[breadcrumbList.length - 1]) {
    breadcrumbList[breadcrumbList.length - 1].active = true;
  }

  // Setup mobile item.
  let mobileItem = breadcrumbList[breadcrumbList.length - 1];
  if (breadcrumbList.length > 1) {
    mobileItem = breadcrumbList[breadcrumbList.length - 2];
  }

  return (
    <UIBreadcrumbs items={breadcrumbList} mobileItem={mobileItem} />
  );
}

export default Breadcrumbs;
