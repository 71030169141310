const initDataLayerObject = (pageType) => {
  let env = process.env.ENVIRONMENT;
  switch (env) {
    case 'development':
      env = 'DEV';
      break;
    case 'test':
      env = 'QA';
      break;
    case 'production':
    case 'prod':
      env = 'PROD';
      break;
    default:
      env = 'DEV';
  }
  let breadcrumb = [];
  if (typeof window !== 'undefined') {
    // Store the current page type in sessionStorage and load the previous ones.
    breadcrumb = JSON.parse(sessionStorage.getItem('breadcrumb')) || [];
    sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
  }
  return {
    environment: env.toUpperCase(),
    country: process.env.REACT_APP_COUNTRY.toUpperCase(),
    type: pageType,
    breadcrumb,
    search_results: {
      search_result_amount: 0,
      search_result_distance: 0,
      search_result_page: 0,
      search_result_zip_code: '',
      search_result_keyword: '',
    },
  };
};

const handlePress = (pageType, locale) => {
  // Page.
  const page = initDataLayerObject(pageType);
  page.language = locale;

  return {
    page,
  };
};

const generateDataLayer = (pageType, locale) => handlePress(pageType, locale);

export default generateDataLayer;
