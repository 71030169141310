import React, { createContext, useMemo } from 'react';

export const TrackingContext = createContext({});

export function TrackingProvider({ children, context }) {
  let optimizelySettings;
  if (typeof window !== 'undefined') {
    optimizelySettings = window.__OPTIMIZELY_SETTINGS__ || {};
  } else {
    optimizelySettings = context?.appConfigurations?.['rbd_tracking.settings']?.optimizely || {};
  }
  const contextValue = {
    optimizelySettings,
  };
  const settingsString = JSON.stringify(contextValue);

  // Memoise the settings, so that we do not cause unintended re-renders.
  // Use primitive type (stringified settings) as dependency, when the object is
  // small enough! Otherwise, look for a different solution with dependencies.
  // Disabling react-hooks/exhaustive-deps, as we've provided a correct
  // dependency, in stringified form.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedContextValue = useMemo(() => contextValue, [settingsString]);

  return (
    <TrackingContext.Provider value={memoizedContextValue}>
      {children}
    </TrackingContext.Provider>
  );
}

export default TrackingContext;
