import { useRouteMatch } from 'react-router-dom';
import { useIntl } from 'react-intl';

function GetRouteFromUrl() {
  // Load the routes for the specific country.
  const routesCore = require('../../config/core/client.routes.json');
  let routesOpCo = {};
  const supportedLanguages = [];
  let currentLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE;
  try {
    routesOpCo = require(`../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
  } catch (e) {}
  const routes = {
    ...routesCore,
    ...routesOpCo,
  };
  for (const lang of Object.keys(routes)) {
    supportedLanguages.push(lang);
  }
  const { locale } = useIntl();
  supportedLanguages.forEach((lang) => {
    if (locale.startsWith(lang)) {
      currentLanguage = lang;
    }
  });
  const currentUrl = useRouteMatch().path;
  const clientRoutes = routes[currentLanguage];
  for (const name of Object.keys(clientRoutes)) {
    if (Object.hasOwn(clientRoutes, name)) {
      const route = clientRoutes[name];
      if (route.url === currentUrl) {
        return { name, ...route };
      }
    }
  }
  return null;
}

export default GetRouteFromUrl;
