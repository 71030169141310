import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import encodeString from 'encodeString';
import UIArticleListItem from '@UI/ArticleOverview/ListItem';
import DateFormatter from 'DateFormatter';

function ArticleListItem({ articleData, divider }) {
  const tagsRaw = [...articleData.categories || [], ...articleData.tags || []];
  const route = useRouteMatch();
  const basePath = route.path.replace(':searchParams*', '');
  let img = null;

  const tags = tagsRaw.map((item) => ({
    text: item,
    url: `${basePath}${encodeString(item.toLowerCase())}/`,
  }));

  if (articleData.image_url) {
    let imageRelativeUrl = articleData.image_url;
    // Check if url is array and contains 's3fs-media' string.
    if (Array.isArray(imageRelativeUrl) && imageRelativeUrl[0].indexOf('s3fs-media') !== -1) {
      // This will return url string after the '/s3fs-media/' substring.
      [, imageRelativeUrl] = imageRelativeUrl[0].split('/s3fs-media/');
      imageRelativeUrl = `/s3fs-media/${imageRelativeUrl}`;
    }
    img = {
      src: imageRelativeUrl,
      alt: articleData.image_alt && articleData.image_alt[0] ? articleData.image_alt : articleData.field_title,
      loading: 'lazy',
      width: '240',
      height: '160',
    };
  } else {
    const svg = articleData.color === 'dark-blue' ? 'darkblue-bg' : 'yellow-blue-red-turquoise-bg';

    img = {
      src: `/articles/static/assets/image/article-overview/${svg}.svg`,
      alt: articleData.field_date,
      title: articleData.field_title,
      loading: 'lazy',
      width: '240',
      height: '160',
    };
  }

  // TODO: Not used anymore. Clean up
  // const Thumbnail = ({articleData}) => {
  //   if (articleData.image_url) {
  //     return (
  //       <a href={articleData.url} className="blog-overview__media aspect-ratio aspect-ratio--3-2 media-block--center">
  //         <img src={articleData.image_url} alt={articleData.image_alt} data-rs-carousel-image=""/>
  //       </a>
  //     )
  //   }
  //   else {
  //     const svg = articleData.color === "dark-blue" ? "darkblue-bg" : "yellow-blue-red-turquoise-bg";
  //     return (
  //       <a href={articleData.url} className={`blog-overview__media blog-overview__media--border-radius blog-overview__media--s aspect-ratio aspect-ratio--3-2 bg-brand--${articleData.color}`}>
  //         <img src={`/articles/static/assets/image/article-overview/${svg}.svg`} alt={articleData.field_date} title={articleData.field_title} data-rs-carousel-image=""/>
  //       </a>
  //     )
  //   }
  // };

  // const Tags = ({tags, path}) => {
  //   if (!tags.length > 0) return null;

  //   const basePath = path.replace(":searchParams*", "");
  //   return (
  //     <div className="link-tags hidden--until-l text-ellipsis">
  //       <ul className="link-tags__list ">
  //         {tags.map(item => <li key={item} className="link-tags__item"><a href={`${basePath}${encodeString(item.toLowerCase())}/`}>{item}</a></li>)}
  //       </ul>
  //     </div>
  //   )
  // };
  let articleDate = articleData.field_date;
  if (process.env.REACT_APP_USE_DATEFORMATTER) {
    articleDate = DateFormatter(articleData.field_date, 'd M Y');
  }
  return (
    <UIArticleListItem date={articleDate} title={articleData.field_title} img={img} tags={tags} url={articleData.url} placeholder={typeof articleData.image_url === 'undefined'} bgColor={articleData.color} divider={divider} />
  );
}

export default injectIntl(ArticleListItem);
