import searchParamsStringify from '@Utils/searchParamsStringify';

function getCanonicalLink(baseUrl, searchParams, canonicalOverrides, pageType) {
  const searchParamsString = searchParamsStringify(pageType, searchParams);

  let canonicalLink = baseUrl + searchParamsStringify(pageType, searchParams);

  // Page's canonical link, can be overridden,
  // from CMS configuration.
  if (canonicalOverrides && Object.keys(canonicalOverrides).length > 0) {
    let overrideValue = '';

    if (canonicalOverrides[canonicalLink]) {
      // First check for full match.
      overrideValue = canonicalOverrides[canonicalLink] || '';
    } else if (searchParams && searchParams.page) {
      const { page } = searchParams;
      searchParams.page = null;
      const noPager = baseUrl + searchParamsStringify(pageType, searchParams);
      searchParams.page = page;

      if (canonicalOverrides[noPager]) {
        overrideValue = canonicalOverrides[noPager];
      }
    }

    if (canonicalOverrides[baseUrl] && overrideValue === '') {
      // Try to apply wildcard for base path override.
      overrideValue = canonicalOverrides[baseUrl] + searchParamsString;
    }

    if (overrideValue.length > 1) {
      canonicalLink = overrideValue;
    }
  }

  return canonicalLink;
}

export default getCanonicalLink;
