const defaultParams = require('./params.config.json');

let countryParams = {};
try {
  countryParams = require(`./${process.env.REACT_APP_COUNTRY}/params.config.json`);
} catch (e) {
}
// Spread country params to override the default params
// otherwise, country params will be added as a property
// with an array of country params
const params = {
  ...defaultParams,
  ...countryParams,
};

export default params;
