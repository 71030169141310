import searchParamsStringify from '@Utils/searchParamsStringify';

function buildPagerLink(pageType, searchParams, page, searchUrl) {
  const pagerBuilder = { ...searchParams };
  pagerBuilder.page = page === 1 ? null : page;
  const url = `${searchUrl}${searchParamsStringify(pageType, pagerBuilder)}`;
  return {
    url,
    // mousedown: dataLayer.buildPaginationClickEvent(page),
  };
}

function buildSearchListPager(pageType, searchParams, totalMatches, searchUrl) {
  const currentPage = searchParams.page ? parseInt(searchParams.page) : 1;
  const itemsPerPage = parseInt(process.env.REACT_APP_SEARCH_ITEMS_PER_PAGE);
  const pages = Math.ceil(totalMatches / itemsPerPage);
  const pager = {
    previous: {},
    next: {},
    pages: [],
    current: currentPage,
  };

  if (currentPage > 1) {
    pager.previous = buildPagerLink(pageType, searchParams, currentPage - 1, searchUrl);
  }

  if (currentPage < pages) {
    pager.next = buildPagerLink(pageType, searchParams, currentPage + 1, searchUrl);
  }

  if (pages > 1) {
    let startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(startPage + 4, pages);

    if (endPage - startPage < 4) {
      startPage = Math.max(1, endPage - 4);
    }

    for (let i = startPage; i <= endPage; i += 1) {
      pager.pages.push({
        ...buildPagerLink(pageType, searchParams, i, searchUrl),
        text: i,
        isCurrent: i === currentPage,
        // mousedown: dataLayer.buildPaginationClickEvent(i),
      });
    }
  }

  return pager;
}

export default buildSearchListPager;
