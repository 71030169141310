import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import dispatchRerender from 'dispatchRerender';
import ArticleListItem from '@Components/ArticleListItem';
import axios from 'axios';
import buildSearchListPager from 'buildSearchListPager';
import Logger from 'logger';

const queryString = require('query-string');

function fetchSearchResults(searchParamsProps, cookies, intl, pageType, queryParams) {
  const callbackAlias = '@Callbacks/getSearchResultsAndAggregations';
  const data = {
    cookies: cookies && cookies.sortBy ? { sortBy: cookies.sortBy } : {},
  };
  return axios.get(`/api/articles/get-callback/?callback=${callbackAlias}&searchParams=${encodeURIComponent(JSON.stringify(searchParamsProps))}&pageType=${pageType}&queryParams=${encodeURIComponent(JSON.stringify(queryParams))}&data=${JSON.stringify(data)}&language=${intl.locale}`);
}

function generateArticles(searchResults) {
  const articles = [];
  const hitKeys = Object.keys(searchResults.hits.hits);

  // Set counter to remove divider line from the last element on page.
  let articleNumber = 1;

  for (const id of hitKeys) {
    if (Object.prototype.hasOwnProperty.call(searchResults.hits.hits, id)) {
      /* The underscore in "_source" is likely part of the Elasticsearch query DSL.
      Elasticsearch uses underscores as part of its API and query syntax,
      and in this case "_source" is a valid property of a search result object returned by Elasticsearch. */
      // eslint-disable-next-line no-underscore-dangle
      const articleData = searchResults.hits.hits[id]._source;
      articles.push(<ArticleListItem key={id} articleData={articleData} divider={hitKeys.length !== articleNumber} />);
      articleNumber += 1;
    }
  }

  return articles;
}

function withSearch(ChildComponent) {
  // TODO: Fix.
  // eslint-disable-next-line sonarjs/cognitive-complexity
  return function SearchWrapper(props) {
    const {
      intl, staticContext, match, location, pageType = 'workforce360',
    } = props;
    const routeMatch = useRouteMatch();
    const history = useHistory();
    // Redirect to trailing slash.
    useEffect(() => {
      if (routeMatch.url.substr(-1) !== '/') {
        history.push(`${routeMatch.url}/`);
      }
    }, [routeMatch.url, history]);

    const searchParamsProps = match.params.searchParams || '';
    const initialData = staticContext?.data || (typeof window !== 'undefined' && window.__ROUTE_DATA__) || {};
    const [state, setState] = useState(initialData);
    const [cookies] = useCookies(['sortBy']);

    useEffect(() => {
      const queryParams = location.search ? queryString.parse(location.search) : {};

      if (window.__ROUTE_DATA__) {
        setState(window.__ROUTE_DATA__);
        delete window.__ROUTE_DATA__;
      } else {
        fetchSearchResults(searchParamsProps, cookies, intl, pageType, queryParams)
          .then((response) => {
            setState(response.data);
            return response.data;
          }).catch((error) => {
            Logger.error(error, 'components/hoc/Search');
          });
      }
      dispatchRerender();
    }, [cookies, intl, location.search, pageType, searchParamsProps]);

    const noResults = !state.searchResults?.hits?.hits?.length;
    const total = state.searchResults?.hits?.total || 0;
    const searchParams = state.searchParams || {};
    const canonicalOverrides = state.canonicalOverridesResponse || null;
    const pager = noResults
      ? {}
      : buildSearchListPager(pageType, searchParams, total, match.path.replace(':searchParams*', ''));
    const articles = noResults ? [] : generateArticles(state.searchResults);

    return <ChildComponent intl={intl} searchParams={searchParams} pager={pager} articles={articles} total={total} canonicalOverrides={canonicalOverrides} {...props} />;
  };
}

export default withSearch;
