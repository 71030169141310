import React from 'react';
import { injectIntl } from 'react-intl';
import { useCookies } from 'react-cookie';
import { useRouteMatch } from 'react-router-dom';
import SortbarUI from '@UI/Sortbar';

function SortBar({ intl, resultsTotal, searchParams }) {
  const route = useRouteMatch();
  const [cookies, setCookie] = useCookies(['sortBy']);

  let from = 1;
  let to = resultsTotal < process.env.REACT_APP_SEARCH_ITEMS_PER_PAGE ? resultsTotal : process.env.REACT_APP_SEARCH_ITEMS_PER_PAGE;
  if (resultsTotal < 1) {
    return null;
  }
  if (searchParams.page && searchParams.page > 1) {
    from += (searchParams.page - 1) * process.env.REACT_APP_SEARCH_ITEMS_PER_PAGE;
    to = searchParams.page * process.env.REACT_APP_SEARCH_ITEMS_PER_PAGE;
    if (to > resultsTotal) {
      to = resultsTotal;
    }
  }

  const changeSortOption = (event) => {
    setCookie('sortBy', event.target.value, { path: route.path.replace(':searchParams*', ''), sameSite: 'lax', secure: !process.env.REACT_APP_ISDEV });
  };
  const { sortBy } = cookies;

  const selectOptions = {
    'date:desc': intl.formatMessage({ id: 'SortBar.SortDateDesc' }),
    'date:asc': intl.formatMessage({ id: 'SortBar.SortDateAsc' }),
  };

  const selectAttr = {
    name: 'sortBy',
    id: 'sortBy',
    defaultValue: sortBy,
    'aria-label': intl.formatMessage({ id: 'AccordionItem.AriaLabel' }),
    onChange: changeSortOption,
  };

  return (
    <SortbarUI count={intl.formatMessage({ id: 'SortBar.Count' }, { from, to, total: resultsTotal })} selectLabel={intl.formatMessage({ id: 'SortBar.SortLabel' })} untouched={sortBy} selectOptions={selectOptions} selectAttributes={selectAttr} />
  );
}

export default injectIntl(SortBar);
